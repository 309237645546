<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2 style="text-align: center">
          All Streams
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" v-for="stream in streams" :key="stream.streamid">
        <custom-card
            @click="openStream(stream)"
        >
          <v-card-title>
            {{ stream.name }}
          </v-card-title>
        </custom-card>
      </v-col>
      <v-col cols="4">
        <custom-card @click="createStreamDialog = true">
          <v-card-text>
            <h2>
              <base-icon>mdi-plus</base-icon>
              Create a Stream
            </h2>
          </v-card-text>
        </custom-card>
      </v-col>
      <v-dialog v-model="createStreamDialog" persistent>
        <v-card>
          <v-card-title>Create a Stream</v-card-title>
          <v-card-text>
            <v-text-field
                v-model="newStreamName"
                label="New Stream Name"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="createStreamDialog=false">Cancel</v-btn>
            <v-btn @click="createStream">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import {Action as StreamAction, Getter as StreamGetter} from '@/store/stream/types'
import BaseIcon from "@/components/base/Icon";
import CustomCard from "@/components/app/CustomCard";
export default {
  name: "AppHome",
  components: {CustomCard, BaseIcon},
  computed: {
    streams() {
      return this.$store.getters[`${[StreamGetter.GetStreams]}`]
    }
  },
  data: () => ({
    createStreamDialog: false,
    newStreamName: '',
  }),
  methods: {
    openStream(stream) {
      console.log(stream)
      this.$router.push({name: 'stream', params: {guid: stream.guid}})
    },
    createStream() {
      let res = this.$store.dispatch(`${[StreamAction.PostStream]}`, {name: this.newStreamName})

      res.then(() => {
        this.createStreamDialog = false
        // reset state
        this.newStreamName = ''
      })
    },
  },
  mounted() {
    this.$store.dispatch(`${[StreamAction.FetchStreams]}`)
  }
}
</script>

<style scoped>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
