<template>
  <v-hover v-slot="{ hover }">
    <v-card
        :elevation="hover? 12:2"
        :class="{ 'on-hover': hover }"
        height="auto"
        v-on="$listeners"
        v-bind="$attrs"
    >
      <slot />
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "custom-card"
}
</script>

<style scoped>

</style>
